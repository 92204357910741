import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import SliceZone from '../components/SliceZone'
import { RichText } from 'prismic-reactjs'
import { Heading, HStack } from '@chakra-ui/react'

const Commitment = ({ data, pageContext }) => {
  const pageContent = data.prismic.commitment.edges[0]
  if (!pageContent) return null
  const page = pageContent.node

  // console.log('pageContent', pageContent)

  const topMenuContent = data.prismic.allTop_menus.edges[0]
  if (!topMenuContent) return null
  const topMenu = topMenuContent.node

  const removeCurrentPost = (posts) => {
    let postsFiltered = []
    for (let index = 0; index < posts.length; index++) {
      var post = posts[index];
      if (post.node._meta.uid === page._meta.uid) { continue }
      else { postsFiltered.push(post) }
    }
    return postsFiltered
  }

  // console.log(page)

  if (page) {
    return (
      <Layout
        topMenu={topMenu}
        activeDocMeta={page._meta}
        lang={pageContext.langIso}
        color="light"
      >
        <Helmet>
          {page.seo_title ?
            <>
              <title>{RichText.asText(page.seo_title)}</title>
              <meta name="og:title" content={RichText.asText(page.seo_title)} />
            </>
            : null}
          {page.seo_description ?
            <meta name="description" content={RichText.asText(page.seo_description)} />
            : null}
          {page.sharing_image ?
            <meta property="og:image" content={page.sharing_image.url} />
            : null}
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />

          <meta name="twitter:title" content={ page.seo_title[0].text }></meta>
          <meta name="twitter:description" content={ page.seo_description[0].text }></meta>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:site" content="@malkin_vitaly"></meta>
          <meta name="twitter:creator" content="@malkin_vitaly"></meta>


        </Helmet>
        {page.title ?
          <Heading
            maxW="1030px"
            mx="auto"
            px={{ base: '1rem', lg: 0 }}
            py='2rem'
            as="h1"
            // mb='2rem'
            fontSize={{ base: '28px', lg: '40px' }}
          >
            {RichText.asText(page.title)}
          </Heading>
          : null}
        <SliceZone
          slices={page.body}
          posts={removeCurrentPost(data.prismic.allPosts.edges)}
          commitments={ data.commitments }
        />
      </Layout>
    )
  }
  return null
}

export const query = graphql`
query commitmentQuery($uid: String, $lang: String) {

  commitments: allPrismicCommitment(filter:{ lang: { eq: $lang}}){
    edges{
      node{
        uid
        id
        prismicId
        lang
        type
        data{
          is_visible
          title{ text }
          intro{ text raw }
          featured_image{ fixed{ src }}
        }
      }
    }
  }

  prismic {
    commitment:allCommitments(uid: $uid, lang: $lang) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lang
            alternateLanguages {
              id
              lang
              type
              uid
            }
          }
          title
          seo_title
          seo_description
          sharing_image
          body {
            ... on PRISMIC_CommitmentBodyYoutube_player {
              type
              primary{
                video
              }
            }
            ... on PRISMIC_CommitmentBodyBooks_list {
              label
              type
            }
            ... on PRISMIC_CommitmentBodyLatest_commitments {
              type
              label
              primary{
                display_thumbnail
              }
            }
            ... on PRISMIC_CommitmentBodyButton {
              type
              label
              primary{
                title1
                link {
                  ... on PRISMIC__Document {
                    _meta {
                      uid
                      type
                      lang
                    }
                  }
                  ... on PRISMIC__ExternalLink{
                    url
                  }
                }
              }
            }
            ... on PRISMIC_CommitmentBodyFull_width_image {
              type
              label
              primary {
                image
                legend
              }
            }
            ... on PRISMIC_CommitmentBodyText {
              type
              label
              primary{
                text
              }
            }
          }
        }
      }
    }
    allPosts(
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lang
            alternateLanguages {
              id
              lang
              type
              uid
            }
          }
          title
          featured_image
          is_visible
        }
      }
    }
    allBooks (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges{
        node{
          _meta{
            id
            uid
            type
            lang
          }
          title
          intro
          cover
        }
      }
    }
    allTop_menus(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Project {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Archive {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export default Commitment
